.Conversation{
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    transition: .4s;
}
.Button{
    display: inline-block;
    height: 3.4rem;
    line-height: 3.4rem;
    font-weight: 600;
    padding: 0 1.5rem;
    text-align: center;
    border: none;
    background-color: #ea8423;
    color: #ffffff;
}
.Button:hover{
    background-color: #190d3f;
    color: #ffffff;
}
.Button svg{
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 10px;
}
.Botline{
    width: 4rem;
    position: relative;
    margin: 0 0 1rem;
}
.Botline::before{
    width: 155%;
    content: '';
    padding-bottom: 155%;
    border-radius: 50%;
    background-color: #ffffff;
    position: absolute;
    bottom: -.5rem;
    right: 0;
    opacity: .05;
}
.Botline img{
    width: 100%;
    height: auto;
    position: relative;
    z-index: 3;
}
.Content{
    padding: 2rem 0 0;
}
@media (min-width: 991px){
    .Content{
        width: 30rem;
        max-width: 100%;
        padding: 0;
    }
}
.Title{
    margin: 0;
    font-size: 3.8rem;
    text-transform: uppercase;
}
.Paragraph{
    color: #C9C9C9;
    margin-bottom: 1.5rem;
}
.Store{
    margin-top: 2rem;
}
.Store a{
    display: block;
}
.Store img{
    display: block;
    width: auto;
    height: 3rem;
}
.Account{
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: 3rem;
    right: 2rem;
    background-color: #121117;
    color: #190d3f;
    font-size: 1.2rem;
    font-weight: 500;
}