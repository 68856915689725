.Indicator {
  display: flex;
  align-items: center;
  padding: 1rem 0.75rem;
}
@media (min-width: 768px) {
  .Indicator {
    padding: 1.5rem 1rem;
  }
}
.Indicator > svg {
  display: block;
  width: 15px;
  height: auto;
  position: absolute;
  top: 0;
  left: -15px;
}
.Indicator > svg polygon {
  fill: rgba(255, 255, 255, 0.85);
}

.Pulse {
  position: relative;
  top: -5px;
  left: -9999px;
  width: 2px;
  height: 2px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  box-shadow: 9999px 0 0 0px #fff;
  animation: pulse 1.5s infinite linear;
  animation-delay: 0.25s;
  transform: translateX(1rem);
}

.Pulse::before,
.Pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 2px;
  height: 2px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
}

.Pulse::before {
  left: 6px;
  box-shadow: 9984px 0 0 0px #fff;
  animation: pulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.Pulse::after {
  left: -6px;
  box-shadow: 10014px 0 0 0px #fff;
  animation: pulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes pulseBefore {
  0% {
    box-shadow: 9984px 0 0 0px #fff;
  }
  30% {
    box-shadow: 9984px 0 0 1px #fff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 0px #fff;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 9999px 0 0 0px #fff;
  }
  30% {
    box-shadow: 9999px 0 0 1px #fff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 0px #fff;
  }
}

@keyframes pulseAfter {
  0% {
    box-shadow: 10014px 0 0 0px #fff;
  }
  30% {
    box-shadow: 10014px 0 0 1px #fff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 0px #fff;
  }
}
.Typing{
  position: fixed;
  bottom: 9rem;
  z-index: -1;
  margin-left: 0.5rem;
  font-size: .8rem;
}

.Name{
  color: #ff0;
  margin-right: 5px;
}
.Pencil{
  display: block;
  width: .75rem;
  height: .75rem;
  margin-right: 1rem;
}
@keyframes pulseAvatar {
  0% {
    opacity: .7;
  }
  30% {
    opacity: 1;
  }
  60%,
  100% {
    opacity: .7;
  }
}
.Avatar {
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  margin-right: 1rem;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.85);
  color: #190d3f;
  font-size: 1.2rem;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  opacity: .5;
  animation: pulseAvatar 1.5s infinite linear;
}
.Avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }