.App {
  width: 100%;
  overflow: hidden;
  position: relative;
}
@media (min-width: 991px) {
  .App {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
}
.Footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem 0;
  text-align: center;
  transition: 0.4s;
}
.FooterInner {
  display: none;
}
@media (min-width: 991px) {
  .FooterInner {
    display: block;
  }
}
@media (min-width: 991px) {
  .FooAway {
    transform: translateY(100%);
  }
}
.Footer img{
  display: block;
  width: 80px;
  height: auto;
  margin-left: 3px;
}