.Course {
  width: 80%;
  min-width: 90%;
  padding: 0 10px;
}
@media (min-width: 574px) {
  .Course {
    width: 50%;
    min-width: 50%;
  }
}
.Course figure {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0;
}
.Course figure::before {
  display: block;
  content: "";
  padding-top: 56.25%;
}
.Course svg {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.Course figure img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.Title {
  font-size: .9rem;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0.5rem 0;
}
.Close {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 0;
  background-color: #190d3f;
  border: solid 2px #190d3f;
  padding: 0;
  text-align: center;
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 5;
}
.Close::after,
.Close::before {
  width: 60%;
  height: 2px;
  content: "";
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.Close::after {
  width: 2px;
  height: 60%;
}
