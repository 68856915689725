.MessageBox{
    width: 100%;
    height: calc(100% - 6rem);
}
.WithSuggestions{
    height: calc(100% - 9rem);
}
.MessageContent{
    width: 100%;
    padding: 2rem 0 6rem;
}

.Message {
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 1rem 0;
    transition: .4s;
  }
  @media (min-width: 768px) {
    .Message {
      padding: 1rem 2rem;
    }
  }
.Avatar {
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.85);
    color: #190d3f;
    font-size: 1.2rem;
    font-weight: 500;
    position: relative;
    overflow: hidden;
  }
  .Avatar img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  .From .Avatar {
    margin-left: 1rem;
    margin-right: 0;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
  .To {
  }
  .MessageArea{
    width: 100%;
  }
  .MessageText {
    min-width: 8rem;
    background-color: rgba(255, 255, 255, 0.85);
    color: #190d3f;
    border-radius: 0 1rem 1rem 1rem;
    padding: 1rem 0.75rem;
    position: relative;
  }
  @media (min-width: 768px) {
    .MessageText {
      padding: 1rem;
    }
  }
  .MessageText > svg {
    display: block;
    width: 15px;
    height: auto;
    position: absolute;
    top: 0;
    left: -15px;
  }
  .MessageText > svg polygon {
    fill: rgba(255, 255, 255, 0.85);
  }
  .From .MessageText {
    margin-left: 4rem;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    border-radius: 1rem 1rem 0 1rem;
  }
  .From .MessageText > svg {
    top: auto;
    left: auto;
    bottom: 0;
    right: -15px;
  }
  .From .MessageText > svg polygon {
    fill: rgba(255, 255, 255, 0.1);
  }
  .MessageText.MessageError {
    border-color: darkred;
    background-color: darkred;
    color: white;
  }
  