.Chatbox{
    max-width: 700px;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    margin: auto;
    position: relative;
}
@media (min-width: 991px){
    .Chatbox{
        height: 100%;
    }
}
.Footer{
    width: 100%;
    height: 5rem;
    border-top: solid 1px rgba(255,255,255,0.1);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1000;
}
@media (min-width: 991px){
    .Footer{
        padding: 0 0 0 1rem;
    }
}
.Fieldset{
    width: 80%;
    margin: 0;
    padding: 0;
    border: none;
    background-color: rgba(255,255,255,0.1);
    border-radius: 2rem;
}
.Input{
    outline: none;
    box-shadow: none;
    border: none;
    border-radius: 2rem 0 0 2rem;
    width: 100%;
    height: 2.8rem;
    padding: .5rem 1rem;
    background-color: transparent;
    color: #fff;
    transition: .4s;
}
.Input::placeholder{
    color: rgba(255,255,255,0.5);
}
.Input:disabled{
    cursor: wait;
}
.Button{
    width: 2.8rem;
    min-width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    margin: 0 0 0 5px;
    padding: 0;
    text-align: center;
    border: none;
    outline: none;
    background-color: transparent;
    color: #fff;
}
.Button svg{
    width: 1rem;
    height: 1rem;
    display: inline-block;
    vertical-align: middle;
}
.Content{
    height: 100%;
}