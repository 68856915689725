.Welcome {
  width: 70rem;
  max-width: 100%;
}
@media (min-width: 991px) {
  .Welcome {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
.Content {
  width: 80%;
  margin: auto;
  padding: 1.4rem;
  color: #ffffff;
  text-align: center;
}
@media (min-width: 991px) {
  .Content {
    width: 50%;
    padding: 2rem;
  }
}
.WelcomeFieldset {
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
}
.WelcomeFieldset input {
  width: 100%;
  margin: 1.4rem 0 1rem;
  padding: .4rem 1rem;
  border-radius: 2rem;
  border: solid 1px rgba(255, 255, 255, 0.5);
  background-color: #000000;
  color: #ffffff;
}
.WelcomeText {
  width: 20rem;
  max-width: 100%;
    font-size: 1.25rem;
    font-weight: 600;
    margin: auto;
    padding: 3rem 0;
}
.WelcomeImage{
  width: 100%;
  position: relative;
  margin: auto;
}
@media (min-width: 574px) {
  .WelcomeImage{
    width: 200px;
  }
}
.WelcomeImage img{
  display: block;
  width: 50%;
  height: auto;
  margin: auto;
}
@media (min-width: 574px) {
  .WelcomeImage img{
    width: 100%;
  }
}
.WelcomeBubble{
  display: inline-block;
  border: solid 1px#ffffff;
  background-color: #ffffff;
  color: #000000;
  border-radius: .75rem 0 .75rem .75rem;
  margin: 1rem 0 0;
  padding: .75rem;
  font-size: .9rem;
  text-align: left;
  white-space: nowrap;
  position: relative;
  z-index: 5;
}
@media (min-width: 574px) {
  .WelcomeBubble{
    border-radius: .75rem .75rem .75rem 0;
    margin: 0;
    position: absolute;
    top: 5%;
    left: 115%;
  }
}
.WelcomeBubble span{
  color: #483297;
}
.WelcomeBubble svg{
  display: block;
  width: 15px;
  height: auto;
  position: absolute;
  top: -14px;
  right: -1px;
}
@media (min-width: 574px) {
  .WelcomeBubble svg{
    top: auto;
    right: auto;
    bottom: -1px;
    left: -14px;
  }
}
.WelcomeForm {
  width: 450px;
  max-width: 100%;
  margin: auto;
}
.WelcomeLabel {
  display: block;
  font-weight: 300;
  margin: 0 0 0.4rem;
}
.WelcomeLabel sup {
  color: red;
}
.WelcomeInput{
  outline: none;
  box-shadow: none;
  border: solid 1px rgba(255, 255, 255, 0.1);
  border-radius: 2rem;
  width: 100%;
  height: 2.8rem;
  margin: 1rem .5rem 0;
  padding: .5rem 1rem;
  background-color: #190c40;
  font-size: .9rem;
  color: #fff;
  transition: .4s;
}
.WelcomeInput::placeholder{
  color: rgba(255,255,255,0.5);
}
.WelcomeAction {
  width: 250px;
  max-width: 100%;
  margin: auto;
}
.WelcomeButton {
  margin-bottom: 1rem;
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  padding: 0 1rem;
  text-align: center;
  border: none;
  background-color: #ea8423;
  color: #ffffff;
  white-space: nowrap;
}
@media (min-width: 574px) {
  .WelcomeButton {
    width: auto;
  }
}
@media (min-width: 991px) {
  .WelcomeButton {
    margin-bottom: 0;
  }
}
.WelcomeButton:hover {
  background-color: #190d3f;
  color: #ffffff;
}

.ButtonRGB {
    position: relative;
    z-index: 1;
    transform: rotate(0deg) translateZ(0);
    transform-origin: center center;
    width: 6rem;
    height: 6rem;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.4);
    animation: rainbow 3s infinite linear;
    -webkit-animation: rainbow 3s infinite linear;
    border-radius: 6rem;
    box-shadow: 0 0 0.9em 0.1em #2c116e, inset 0.09em 0 0.3em 0.06em #de66e4;
    transform-style: preserve-3d;
    perspective: 6rem;
    margin: auto;
}

@keyframes rainbow {
  0% {
    transform: rotate(0deg) translateZ(0);
    box-shadow: 0 0 0.9em 0.1em #2c116e, inset 0.09em 0 0.3em 0.06em #de66e4;
  }
  25% {
    transform: rotate(90deg) translateZ(0);
    box-shadow: 0 0 0.9em 0.1em #28126a, inset 0.09em 0 0.3em 0.06em #34ceaa;
  }
  50% {
    transform: rotate(180deg) translateZ(0);
    box-shadow: 0 0 0.9em 0.1em #28126a, inset 0.09em 0 0.3em 0.06em #19b3f5;
  }
  75% {
    transform: rotate(270deg) translateZ(0);
    box-shadow: 0 0 0.9em 0.1em #28126a, inset 0.09em 0 0.3em 0.06em #3d52ac;
  }
  100% {
    transform: rotate(360deg) translateZ(0);
    box-shadow: 0 0 0.9em 0.1em #28126a, inset 0.09em 0 0.3em 0.06em #de66e4;
  }
}
.ButtonRGB > div {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.ButtonRGB .Green {
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 60%,
    rgba(115, 213, 186, 0.8) 100%
  );
  transform: rotateY(0) skew(14deg) rotate3d(1.1, 1, 0.9, 0);
  animation: curve-rotate-green 6s infinite linear;
}

.ButtonRGB .Pink {
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    rgba(215, 115, 229, 0.8) 100%
  );
  transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 0);
  animation: curve-rotate-pink 3s infinite linear;
}

@-webkit-keyframes curve-rotate-green {
  0% {
    transform: rotateY(0) skew(14deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(0) skew(14deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(0) skew(14deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
@-moz-keyframes curve-rotate-green {
  0% {
    transform: rotateY(0) skew(14deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(0) skew(14deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(0) skew(14deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
@-o-keyframes curve-rotate-green {
  0% {
    transform: rotateY(0) skew(14deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(0) skew(14deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(0) skew(14deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
@keyframes curve-rotate-green {
  0% {
    transform: rotateY(0) skew(14deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(0) skew(14deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(0) skew(14deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
@-webkit-keyframes curve-rotate-pink {
  0% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
@-moz-keyframes curve-rotate-pink {
  0% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
@-o-keyframes curve-rotate-pink {
  0% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
@keyframes curve-rotate-pink {
  0% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 0deg);
  }
  50% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 180deg);
  }
  100% {
    transform: rotateY(180deg) skew(14deg) rotate3d(1.1, 1, 0.9, 360deg);
  }
}
.ButtonIcon{
  width: 2rem;
  opacity: .5;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  transition: .4s;
}
.ButtonIcon:hover{
  opacity: 1;
}
.ButtonIcon img{
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
}