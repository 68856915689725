.Suggestions {
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  position: relative;
  z-index: 5;
}
@media (min-width: 991px) {
  .Suggestions {
    padding-left: 1rem;
  }
}
.SuggestionsWrap {
  width: 100%;
  display: flex;
}
.Suggestions button,
.Suggestions a {
  -webkit-appearance: none;
  appearance: none;
  border: solid 1px rgba(255,255,255,0.1);
  border-radius: 2rem;
  background-color: transparent;
  color: #fff;
  margin: 0 0.5rem 0 0;
  padding: 0 1rem;
  height: 2.2rem;
  line-height: 2.2rem;
  line-height: calc(2.2rem - 2px);
  white-space: nowrap;
  font-size: .9rem;
  transition: .4s;
}
.Suggestions button:hover,
.Suggestions a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}