.Article {
    width: 80%;
    min-width: 90%;
    padding: 1rem;
    border-radius: 5px;
    background-color: #190d3f;
    color: #fff;
    cursor: pointer;
    transition: .4s;
  }
  @media (min-width: 574px) {
    .Article {
      width: 60%;
      min-width: 60%;
    }
  }
  .Article figure {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin: 0;
  }
  .Article figure::before {
    display: block;
    content: "";
    padding-top: 56.25%;
  }
  .Article figure img {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .Title {
    font-size: 1rem;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0.5rem 0;
  }
  .Article p{
      font-size: .9rem
  }
  .Close {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    border-radius: 0;
    background-color: #190d3f;
    border: solid 2px #190d3f;
    padding: 0;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
  }
  .Close::after,
  .Close::before {
    width: 60%;
    height: 2px;
    content: "";
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .Close::after {
    width: 2px;
    height: 60%;
  }
  .ModalArticle{
    width: 50rem;
    max-width: 100%;
    margin: 3rem auto;
    position: relative;
  }
  .ModalFigure{
    width: 100%;
    max-width: 100%;
    margin: 0 auto 1rem;
  }
  .ModalFigure img{
    display: block;
    width: 100%;
    height: auto;
  }
  .ModalTitle{
    font-size: 1.2rem;
    font-weight: 500;
    margin: 1rem 0;
  }