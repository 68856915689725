*,
*::before,
*::after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  background-color: #190c40;
  /* background-image: url('./assets/texture.svg');
  background-size: cover;
  background-repeat: repeat-y;
  background-position: top center; */
}
/* @media (min-width: 991px){
  body{
    background-size: 100% 100%;
  }
} */
code {
  font-family: Menlo, Monaco, Consolas, monospace;
}
a{
  text-decoration: none;
  color: #190d3f;
}
p{
  margin: 0 0 1rem;
}
input,
textarea,
select,
button{
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  box-shadow: none;
  border-radius: 0;
  font-family: 'Saira', sans-serif;
}
input,
textarea,
select{
  font-size: 1rem;
}
button{
  font-size: 1rem;
  cursor: pointer;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container{
  max-width: 90%;
}

.d-none {
  display: none;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: -ms-flexbox;
  display: flex;
}

.d-inline-flex {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none;
  }
  .d-sm-inline {
    display: inline;
  }
  .d-sm-inline-block {
    display: inline-block;
  }
  .d-sm-block {
    display: block;
  }
  .d-sm-table {
    display: table;
  }
  .d-sm-table-row {
    display: table-row;
  }
  .d-sm-table-cell {
    display: table-cell;
  }
  .d-sm-flex {
    display: -ms-flexbox;
    display: flex;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none;
  }
  .d-md-inline {
    display: inline;
  }
  .d-md-inline-block {
    display: inline-block;
  }
  .d-md-block {
    display: block;
  }
  .d-md-table {
    display: table;
  }
  .d-md-table-row {
    display: table-row;
  }
  .d-md-table-cell {
    display: table-cell;
  }
  .d-md-flex {
    display: -ms-flexbox;
    display: flex;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none;
  }
  .d-lg-inline {
    display: inline;
  }
  .d-lg-inline-block {
    display: inline-block;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-table {
    display: table;
  }
  .d-lg-table-row {
    display: table-row;
  }
  .d-lg-table-cell {
    display: table-cell;
  }
  .d-lg-flex {
    display: -ms-flexbox;
    display: flex;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none;
  }
  .d-xl-inline {
    display: inline;
  }
  .d-xl-inline-block {
    display: inline-block;
  }
  .d-xl-block {
    display: block;
  }
  .d-xl-table {
    display: table;
  }
  .d-xl-table-row {
    display: table-row;
  }
  .d-xl-table-cell {
    display: table-cell;
  }
  .d-xl-flex {
    display: -ms-flexbox;
    display: flex;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

@media print {
  .d-print-none {
    display: none;
  }
  .d-print-inline {
    display: inline;
  }
  .d-print-inline-block {
    display: inline-block;
  }
  .d-print-block {
    display: block;
  }
  .d-print-table {
    display: table;
  }
  .d-print-table-row {
    display: table-row;
  }
  .d-print-table-cell {
    display: table-cell;
  }
  .d-print-flex {
    display: -ms-flexbox;
    display: flex;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

.flex-row {
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.flex-fill {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.flex-grow-0 {
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.flex-grow-1 {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.justify-content-start {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-content-end {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-content-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-content-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-content-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-items-start {
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-items-end {
  -ms-flex-align: end;
  align-items: flex-end;
}

.align-items-center {
  -ms-flex-align: center;
  align-items: center;
}

.align-items-baseline {
  -ms-flex-align: baseline;
  align-items: baseline;
}

.align-items-stretch {
  -ms-flex-align: stretch;
  align-items: stretch;
}

.align-content-start {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.align-content-end {
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.align-content-center {
  -ms-flex-line-pack: center;
  align-content: center;
}

.align-content-between {
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.align-content-around {
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.align-self-auto {
  -ms-flex-item-align: auto;
  align-self: auto;
}

.align-self-start {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.align-self-center {
  -ms-flex-item-align: center;
  align-self: center;
}

.align-self-baseline {
  -ms-flex-item-align: baseline;
  align-self: baseline;
}

.align-self-stretch {
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-sm-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .align-items-sm-start {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .align-items-sm-end {
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .align-items-sm-center {
    -ms-flex-align: center;
    align-items: center;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center;
    align-self: center;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-md-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  .justify-content-md-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-content-md-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-content-md-center {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .align-items-md-start {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .align-items-md-end {
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .align-items-md-center {
    -ms-flex-align: center;
    align-items: center;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .align-self-md-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .align-self-md-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .align-self-md-center {
    -ms-flex-item-align: center;
    align-self: center;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-lg-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .align-items-lg-start {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .align-items-lg-end {
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .align-items-lg-center {
    -ms-flex-align: center;
    align-items: center;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center;
    align-self: center;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-xl-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .align-items-xl-start {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .align-items-xl-end {
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .align-items-xl-center {
    -ms-flex-align: center;
    align-items: center;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center;
    align-self: center;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}
.mt-1{
  margin-top: 1rem;
}
.mr-1{
  margin-right: 1rem;
}
.mb-1{
  margin-bottom: 1rem;
}
.ml-1{
  margin-left: 1rem;
}
.mt-2{
  margin-top: 2rem;
}
.mr-2{
  margin-right: 2rem;
}
.mb-2{
  margin-bottom: 2rem;
}
.ml-2{
  margin-left: 2rem;
}
.mt-3{
  margin-top: 3rem;
}
.mr-3{
  margin-right: 3rem;
}
.mb-3{
  margin-bottom: 3rem;
}
.ml-3{
  margin-left: 3rem;
}
.w-100{
  width: 100%;
}
.h-20{
  height: 20rem;
}
.h-22{
  height: 22rem;
}
.h-25{
  height: 25rem;
}
html .ReactModal__Overlay{
  z-index: 1015;
}
html .__react_component_tooltip{
  z-index: 1001;
}
html .__react_component_tooltip{
  background-color: rgba(255,255,255,0.1);
}
html .__react_component_tooltip[data-id="tooltip"]::after{
  border-top-color: rgba(255,255,255,0.1);
  bottom: -7px;
}